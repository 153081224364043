import "./footer.scss"

export function Footer() {
    return (
        <div className="footer">
            <div className="footer-info">
                <a href="https://www.instagram.com/hcankaynak/">© 2022 avukatumutozgur.com - powered by HcK</a>
            </div>
        </div>
    )
}