import "./navBar.scss"

export function NavBar() {

    const navBarBody = () => {
        return (
            <div className="navbar-body">
                <div className="navbar-body-item" onClick={() => {
                    goToElement("personal-info")
                }}>
                    <p>Hakkımda</p>
                </div>

                <div className="navbar-body-item" onClick={() => {
                    goToElement("working-areas")
                }}>
                    <p>Çalışma Alanlarım</p>
                </div>

                <div className="navbar-body-item" onClick={() => {
                    goToElement("contact-container")
                }}>
                    <p>İletişim</p>
                </div>
            </div>
        )
    }

    const goToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    const goToElement = (id: string) => {
        // @ts-ignore
        document.querySelector('#' + id).scrollIntoView({
            behavior: 'smooth',
        });
    }

    const header = () => {
        return (
            <div onClick={() => {
                goToTop();
            }}
                 className="Header">
                <h2>Avukat Umut Özgür</h2>
            </div>
        )
    }


    return (
        <div className="NavBar">
            {header()}
            {navBarBody()}
        </div>
    )
}
