import {LandingPage} from "../LandingPage/LandingPage";
import {PersonalInfo} from "../PersonelInfo/PersonalInfo";
import {WorkingAreas} from "../WorkingAreas/WorkingAreas";
import {Contact} from "../Contact/Contact";
import {Footer} from "../Footer/Footer";
import "./homePage.scss";

export function HomePage() {
    return (<div className="HomePage">
        <LandingPage/>
        <PersonalInfo/>
        <WorkingAreas/>
        <Contact />
        <Footer />
    </div>)
}