import "./cardItem.scss"
import React from "react";

export interface ICardItem {
    key?: number;
    icon: React.ReactNode;
    header: string;
    text: string;
}

export function CardItem(props: ICardItem) {

    const cardItemImage = () => {
        return (
            <div className="card-item-icon">
                {props.icon}
            </div>
        )
    }

    const cardText = () => {
        return (
            <div className="card-body">
                <h3>{props.header}</h3>
                <p>{props.text}</p>
            </div>
        )
    }

    return (
        <div className="card-item-container" key={props.key}>
            {cardItemImage()}
            {cardText()}
        </div>
    )
}
