import "./personalInfo.scss"

export function PersonalInfo() {

    const personalTextVar = "Ankara Üniversitesi Hukuk Fakültesi mezuniyetinin ardından, avukatlık stajını verimli bir şekilde yaparak tecrübe kazanan Özgür; stajının sonrasında Ankara’da kendi bürosunu açarak, müvekkillerine ekibiyle birlikte hukuki hizmet sunmaya başlamıştır. Hukuk büromuz başta Ankara ve Mersin olmak üzere, Türkiye’nin her yerinde faaliyet göstermektedir. \n" +
        " Hacettepe Üniversitesi Özel Hukuk anabilim dalında yüksek lisans eğitimini sürdürmekte olup, iyi derecede İngilizce bilmektedir. "

    const personalPhoto = () => {
        return (<div className="personal-photo">
            <img src="umut_pp2.jpeg" alt="umut-ömür-can-özgür"/>
        </div>)
    }

    const personalHeader = () => {
        return (<div className="personal-header">
            <h2>Av. Umut Ömür Can ÖZGÜR </h2>
            <h3>Kurucu Ortak</h3>
        </div>)
    }

    const boldTextPairs = (key: string, text: string) => {
        return (
            <div className="bold-pairs">
                <p className="key">
                    {key + ": "}
                </p>

                <p className="text">
                    {text}
                </p>
            </div>
        )
    }


    const personalText = () => {
        return (<div className="personal-text">
            <p>{personalTextVar}</p>
            {boldTextPairs("Yabancı Dil", "İngilizce")}
            {boldTextPairs("Mail", "av.umutomurcanozgur@gmail.com")}

        </div>)
    }

    return (
        <div className="personal-info" id="personal-info">
            <h1>
                Hakkımda
            </h1>
            {personalPhoto()}
            {personalHeader()}
            {personalText()}
        </div>)
}