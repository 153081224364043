import "./landingPage.scss"

export function LandingPage() {

    const landingPageText = () => {
        return (<div className="landing-text">
            <h3>GÜVENİLİR VE ETKİLİ HUKUKİ DANIŞMANLIK HİZMETİ</h3>
            <p>
                Avukat Umut Özgür Hukuk Bürosu, Ankara ve Mersin merkezli bir hukuk bürosu olup tüm Türkiye genelinde
                müvekkillerine hızlı ve etkin bir şekilde avukatlık ve hukuki danışmanlık hizmeti vermektedir. Faaliyet
                gösterdiği çalışma alanlarında müvekkillerinin hukuki sorunlarının verimli ve doğru şekilde
                çözümlenmesini amaçlamaktadır. Hukuki işlem ve uyuşmazlıklarınızın çözümü kapsamında büromuzca, güncel
                içtihatlar ve mevzuat ışığında süratli ve şeffaf bir hukuki destek sağlanmaktadır.
            </p>
        </div>)
    }

    return (<div className="LandingPage">
        <img className="landing-image" src="simple-definition-of-law.jpg" alt="homepage-icon"/>
        {landingPageText()}
    </div>)
}